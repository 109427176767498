import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { configureBEMClasses } from '../../../constants/functions';

import styles from './styles.module.scss';
const classes = configureBEMClasses(styles);

function LinkButton(props) {
    const linkClasses = [classes.link, props.linkClasses].join(' ');

    let link = (<Link to={props.url} className={linkClasses}>{props.children}</Link>);

    if (props.isOuterLink) {
        link = (<a href={props.url} className={linkClasses}  rel="noopener" target={props.targetUrl}>{props.children}</a>)
    }

    return link;
}

LinkButton.propTypes = {
    isOuterLink: PropTypes.bool,
    url: PropTypes.string.isRequired,
    targetUrl: PropTypes.string,
    linkClasses: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default LinkButton;