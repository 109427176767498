import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import Img from 'gatsby-image';

const imageTypes = ['fluid', 'fixed', 'svg', 'normal'];
const getAllImagesQuery = graphql`
query GetAllImages {
  allFile(filter: { sourceInstanceName: { eq: "images" } }) {
    edges {
      node {
        publicURL
        base
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
}
`;

function Image(props) {
  const data = useStaticQuery(getAllImagesQuery);

  const imagesArray = data.allFile.edges;

  const getImage = (images) => {
    const searchedImageBase = props.imageName + "." + props.imageExtention;

    let searchedImage = null;

    images.some((edge) => {
      const image = edge.node;

      if (searchedImageBase === image.base) {
        searchedImage = image;
        return true;
      }

      return false;
    })

    return searchedImage;
  }

  const getHTMLimage = () => {
    const image = getImage(imagesArray);

    let htmlImage = null;

    switch (props.imageType) {
      case "fluid":
        htmlImage = (<Img loading="eager" fluid={image.childImageSharp.fluid} alt={props.alt} />);
        break;
      case "fixed":
        htmlImage = (<Img loading="eager" fixed={image.childImageSharp.fixed} alt={props.alt} />);
        break;
      case "svg":
        htmlImage = (<img src={image.publicURL} alt={props.alt} className={props.imageClasses} />);
        break;
      case "normal":
        const normalImage = require(
          '../../../assets/images' +
          props.pathToNormalImageFolder +
          '/' +
          props.imageName +
          '.' +
          props.imageExtention
        );

        htmlImage = (<img src={normalImage} alt={props.alt} className={props.imageClasses} />);
        break;
      default:
        console.error(
          `There no such a gatsby image type "${props.imageType}", did you mean "${imageTypes.join(', ')}"`
        )
        break;
    }

    return htmlImage;
  }

  return (
    getHTMLimage()
  );
}

Image.propTypes = {
  imageType: PropTypes.string.isRequired,
  imageName: PropTypes.string.isRequired,
  imageExtention: PropTypes.string.isRequired,
  imageClasses: PropTypes.string,
  pathToNormalImageFolder: PropTypes.string,
  alt: PropTypes.string,
};

export default Image;